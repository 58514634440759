var posList = {};
var pos = "";
var closeClicked = false;
var openPopupSource = "";
var clusterflag = false;
var delAddrStoreDist = "";
var clusterStoreDist = "";
var finalClusterStoreDist = "";
var cookieMatch, lookupdata;
var zipcodeFetch = "";
var deliverAddrZip = "";
var leadMakeStoreAssigned = false;
var wlsinglepage = false;
var singlepagestorelocatorzipcodeerror = false;
var maplocationzipdesktop = $("#mapLocationZipDesktop");
var pricingForLabel = $("#pricingForLabel");
var findYourStoreLabel = $("#findYourStoreLabel");
var storeLocatorFormZipCode = $(".store-locator form.zipcode");
var storeLocaterModal = $("#storeLocaterModal");
var storeDetailModal = $("#rac-store-details-modal");
var storeinformationrow = $('.store-information-row');
var submitContactInfo = "",
    noStoreFlag = "",
    NSFMyStore = "";
var DeliveryStoreSelection = false;
var storeData = [];

$(document).ready(function () {

    setTheStoreLocatorModalToDefault();
    displaySubmitContactInfoLink();
    storeChangeWarningToggler();
    /** * JQUERY to send Zip Code to backend** */
    $("body").on("click", "#enterZipCodeGoButton_Nav", function () {
        if (validateHeaderZipCode() ) {
		    var zipCode = $(".enter-zip-code:visible").val();
		    openPopup(zipCode,"store-locator");
        }
	    storeChangeWarningToggler();
    });
	
	$('body').on('click', '#change-store', function () {
		$(".showAvailableStore").css("display", "none");
		$('.storeContainerInputForm').css('display', 'block');
	});
    
	//Fix for multiple Store Locator pop ups
	if ($(window).width() < 768) {
		$(".headerContent").css("display", "none!important");
	} else {
		$("#mobileCategoryMenu").css("display", "none!important");
	}

	window.triggerClickOnEnter(".storeLocatorPopup_link");
    
	$(document).on("focus", ".store-locator-accessibility-anchor", function () {
		$(this).siblings(".minessota-store").find("#enterZipCode_Nav").focus();
	});
    
	$('#wrapper-store').on('click', '#change-store', function (e) {
		"use strict";
		e.preventDefault();
		$('.nearest-store').css('display', 'none');
		$('.storeContainerInputForm').css('display', 'block');
		$('.skipSelectAStore').css("display", "block");
	});

    var isBlocked = getCookieByName("geolocationblocked");
    var geoSelected = getCookieByName("geolocationselected");

    displayHeaderStoreInfo(geoSelected);

    if (geoSelected == "Yes" && isBlocked !== "Yes") {
        maplocationzipdesktop.html(pricingForLabel.html());
    } else {
	    if ($.storeCookie("zipCode") != "") {
		    maplocationzipdesktop.html(pricingForLabel.html());
	    } else {
		    maplocationzipdesktop.html($("#findYourStoreLabel").html());
	    }
    }
    var storeCookie =  getCookieByName("storeCookie");
    if (storeCookie == "" || storeCookie == undefined || storeCookie === null) {
        setCookie("geoFirstTime", "", -1, "", true);
        setCookie("geolocationblocked", "", -1, "", true);
        setCookie("geolocationselected", "", -1, "", true);
        setCookie("ifStores", "", -1, "", true);
        setCookie("assignByGeo", "", -1, "", true);
    }

    $(".toggle-search-zip").click(function () {
        storeLocatorFormZipCode.removeClass("error");
        $(".store-locator .form-input-zip-section .invalid-zipcode").hide();
        $(".store-locator .form-input-zip-section .unable-to-locate").hide();
        $(".store-locator .hide-zip").toggle();
        $(".store-locator .change-zip").toggle();
        $(".showAvailableStore").show();
    });

    $("#storeLocaterModal .modal-header .return-to-map, #storeLocaterModal .modal-header .close").click(function () {
        $('#storeLocaterModal .modal-header').removeClass('padding20');
		$('#storeLocaterModal .modal-header .modal-title').removeClass('margintzero');
        $('#storeLocaterModal .modal-header .return-to-map').removeClass('left');
        $('#storeLocaterModal .showAvailableStore .store-preview .location, #storeLocaterModal .showAvailableStore .store-preview .contact, #storeLocaterModal .showAvailableStore .store-preview .store-hours, #storeLocaterModal .showAvailableStore .store-preview .action').removeClass('storelocator_spacing');
        $('#storeLocaterModal .showAvailableStore .store-preview .action  .return-to-map').removeClass('marginT10');
    });
    $("body").on("click", ".return-to-map", function () {
        $(".return-to-map").addClass("hide");
        $(".store-locator #store-map, .store-locator #stores-available, .store-locator .view-stores-near-zipcode").show();
        $(".storeContainerInputForm .selected-zip-container").removeClass("hide");
        $(".store-locator .modal-body").removeClass("store-preview-padding");
        $(".store-locator .modal-title").text($("#storelocatorlocalizeddata").data("storelocatorheader"));
        storeLocatorFormZipCode.removeClass("error");
        $(".store-locator .form-input-zip-section .invalid-zipcode").hide();
        $(".store-locator .form-input-zip-section .unable-to-locate").hide();
        $(this)
            .parents(".selected-zip-container")
            .siblings(".form-input-zip-section")
            .removeClass("form-input-zip-overlay");
        $(this)
            .parents(".selected-zip-container")
            .siblings(".form-input-zip-section")
            .hide();
        $(".store-locator .hide-zip").hide();
        $(".store-locator .change-zip").show();
        $(".store-preview").hide();
	    storeChangeWarningToggler();
    });

    $("body")
        .off("click.toggleSearchZip")
        .on("click.toggleSearchZip",
            ".storeContainerInputForm .toggle-search-zip",
            function () {
                $(".enter-zip-code").val("");
                if ($(".store-locator .hide-zip").is(":visible")) {
                    $(this)
                        .parents(".storeContainerInputForm .selected-zip-container")
                        .siblings(".form-input-zip-section")
                        .addClass("form-input-zip-overlay");
                    $(this)
                        .parents(".storeContainerInputForm .selected-zip-container")
                        .siblings(".form-input-zip-section")
                        .show();
                } else {
                    $(this)
                        .parents(".storeContainerInputForm .selected-zip-container")
                        .siblings(".form-input-zip-section")
                        .removeClass("form-input-zip-overlay");
                    $(this)
                        .parents(".storeContainerInputForm .selected-zip-container")
                        .siblings(".form-input-zip-section")
                        .hide();
                }
                $(this).toggleClass("active");
            }
        );

    $("body").on("click", "li.map-item", function (evt) {
        $(".return-to-map").removeClass("hide");
        if ($(evt.target).hasClass("makemystore")) {
			$(".checkstoreerror").hide();
            storeLocaterModal.modal("hide");
            handleSpinner();
            jQuery(".rac-spinner-wrapper").show();
            $(this).addClass("selectedStore");
            $(this)
                .closest(".store-details")
                .children(".makemystore")
                .addClass("btn-selected");
            displayMyStore();

            var priorStoreNum = $.storeCookie("storeNumber");
            var priorStoreZip = $.storeCookie("zipCode");
            setCookie("makeMyStoreClicked", "Yes", 7, "", true);
            setCookie("assignbuttonclicked", "No", 7, "", true);
            setCookie("DeliveryStoreAssigned", "Yes", 7, "", true);
            makeMyStore(
                $(evt.target).data("storenum"),
                $(evt.target).data("distance"),
                $(evt.target).data("address"),
                $(evt.target).data("storechangeevent"),
                priorStoreNum,
                priorStoreZip
            );
            if (NSFMyStore == true) {
                $("#thankYouModal").modal("show");
            }
        } else {
            var $details = $(this).find(".store-details");
            var str = $details.data("phone");
            var res = str.replace(/\s+/g, "");

            var getDirectionsLink =
                "https://www.google.com/maps/dir/?api=1&destination=" +
                $details.data("lat") +
                "," +
                $details.data("lng");
            var storeDetails =
                "<div><div class='location clearfix'><div class='row'><span class='col-xs-1 col-md-1 glyphicon glyphicon-map-marker pull-left'></span><span class='col-xs-6 col-md-7 address'><span>" +
                $details.data("address") +
                "</span></span><span class='distance col-xs-4 col-md-4 pull-right'><span>" +
                $details.data("distance") +
                "</span>&nbsp;" + ACC.miles +"</</span></div></div><div class='contact'><span class='number'><img src=/_ui/responsive/common/images/RAC-iconImages/phone.svg><a href=tel:" +
                res +
                ">" +
                $details.data("phone") +
                "</a></span></div><div>" +
                $details.data("franchise") +
                "</div><div class='store-hours'><h5>"+ ACC.storeHours +"</h5>" +
                $details.data("timings") +
                "</div><div class='action'><a class='rac-button get-directions text-uppercase display-inline-block' target='_blank' href='" +
                getDirectionsLink +
                "'>"+ ACC.getDirections +"&nbsp;<img src=/_ui/responsive/common/images/RAC-iconImages/Button-arrow-white.svg /></a><button class='return-to-map rac-button btn-block text-blue shadow-none'>" + ACC.backLbl +"</button></div></div>";
          
          $(
                ".store-locator #store-map,.store-locator #stores-available, .store-locator .view-stores-near-zipcode, .store-locator .form-input-zip-section"
            ).hide();
            $(".storeContainerInputForm .selected-zip-container").addClass(
                "hide"
            );
	        $(".storeChangeWarning").hide();
            $(".storeContainerInputForm .form-input-zip-section").removeClass(
                "form-input-zip-overlay"
            );
            $(".store-preview").html(storeDetails).show();
            $('#storeLocaterModal .modal-header').addClass('padding20');
            $('#storeLocaterModal .modal-header .modal-title').addClass('margintzero');
            $('#storeLocaterModal .modal-header .return-to-map').addClass('left');
            $('#storeLocaterModal .showAvailableStore .store-preview .location, #storeLocaterModal .showAvailableStore .store-preview .contact, #storeLocaterModal .showAvailableStore .store-preview .store-hours, #storeLocaterModal .showAvailableStore .store-preview .action').addClass('storelocator_spacing');
            $('#storeLocaterModal .showAvailableStore .store-preview .action  .return-to-map').addClass('marginT10');
            $(".store-locator .modal-body").addClass("store-preview-padding");
            $(".store-locator .modal-title").text(
                $("#storelocatorlocalizeddata").data("storeinfoheader")
            );
            storeDetailModal.modal("hide");
            storeLocaterModal.modal("show");
            $(".showAvailableStore").show();
            $("#stores-available").hide();
            $(".store-preview").show();
        }
    });

    $(".locationSearchCloseButton").click(function () {
        closeClicked = true;
        $("body").removeClass("scrollHidden");
        var assignclick = getCookieByName("assignbuttonclicked");
        if (assignclick == "Yes") {
            setCookie("assignbuttonclicked", "No", 7, "", true);
        }
    });

    $(".locationSearchCloseButton").click(function () {
        var storelocatorpopuplink = $(".storeLocatorPopup_link");
        $(".selected-zip").val("");
        $(".enter-zip-code").val("");
        $("body").removeClass("scrollHidden");
        storelocatorpopuplink.qtip("hide");
        storelocatorpopuplink
            .parents(".panel-heading")
            .removeClass("open");
        $(".store-locator .form-input-zip-section .invalid-zipcode").hide();
        $(".storeContainerInputForm").show();
        $(".minessota-store .skipSelectAStore").show();
        $(".showAvailableStore").hide();
        $("#store-map, #stores-available").show();
        $(".store-preview").hide();
        $(".storeContainerInputForm .form-input-zip-section").show();
        $(".storeContainerInputForm .selected-zip-container").addClass("hide");
    });

    //moved this section from header.js to here as it is common to across all the pages
    $(".storeLocatorPopup_link, .in-stock-update-link, .change-store-link").on("click", function () {
        deliveryStoreSelection = false;
        leadMakeStoreAssigned = false;
        $(".modal-body").addClass("store-preview-padding");
        $(".view-stores-near-zipcode").addClass("find-a-store");
        var zipcode = $.storeCookie("zipCode");
        $(".searchnostorefound").hide();
        if ($(window).width() < 768) {
            // $("#qtip-1").offset({top:44,left:193});
            $("body").addClass("scrollHidden");
        }

        var enterZipCodeNav = $("#enterZipCode_Nav");
		var zipCodeRegex = /^\d{5}$/;
        if (zipcode === "" || typeof zipcode === "undefined" || zipcode === null) {
            setTheStoreLocatorModalToDefault();
            storeLocaterModal.modal("show");
        } else if (zipCodeRegex.test(zipcode)){
        	handleSpinner();
        	jQuery(".rac-spinner-wrapper").show();
        	enterZipCodeNav.val(zipcode);
        	setCookie("popUpTrigger", "Header");
         	var _changeStoreEvent = "store-locator";
    		if(this.id == 'in-stock-update-link') {
        	    _changeStoreEvent = "pdp-change-store-link";
        	} else if (RAC.pdpChangeStoreClicked !== undefined && RAC.pdpChangeStoreClicked) {
        	    _changeStoreEvent = "pdp-change-store-link";
        	    RAC.pdpChangeStoreClicked=false;
        	}
        	openPopup(zipcode, _changeStoreEvent);
	        $(".storeChangeWarning").show();
        } else {
            setCookie("popUpTrigger", "Header");
        	setTheStoreLocatorModalToDefault();
        	enterZipCodeNav.val(zipcode);
        	displayInvalidZipError();
        }

        $("#sessionTimeoutContainer, #headerLoginContainer").modal("hide");
        $(this).parents(".panel-heading").addClass("open");
        var zipcodeValue = $("#zipCodeSelectedDesktop").text();

        if ($.storeCookieData().length == 0) {
            if (zipcodeValue == "" || zipcodeValue == "undefined" || zipcodeValue == null) {
                $(".minessota-store")
                    .removeClass("no_display_pop_up")
                    .addClass("display_pop_up");
                $(".map-shown").addClass("no_display_pop_up");
                $(".pop_up_cookie_saved")
                    .removeClass("display_pop_up")
                    .addClass("no_display_pop_up");
            }
        }
    });

    var findMyStore = $("#findMyStore");
	if ((pricingForLabel.length === 0 || findYourStoreLabel.hasClass('hide')) &&
		(findYourStoreLabel.length === 0 || findYourStoreLabel.hasClass('hide')) &&
		(findMyStore.length === 0 || findMyStore.hasClass('invisible'))) {
		RAC.coreDataLayer['storeLocatorHidden'] = 'true';
		RACGA4.coreDataLayer['storeLocatorHidden'] = 'true';
	}
});

function displayHeaderStoreInfo(geoSelected, source) {
	"use strict";
	String.prototype.contains = function(it) {
		return this.indexOf(it) !== -1;
	};
	storeData = $.storeCookieData();
	if(storeData.length != 0) {
		var storeTown = storeData.town;
		var zipCode = storeData.zipCode;
		var isoShortCode = storeData.isoShortCode;

		$("#myStoreText").text(
			"My Store: " + storeTown + ", " + isoShortCode
		);
		$(".productDetailsStoreAddress").text(isoShortCode + ".");
		if(isoShortCode == 'NC'){
			$(".nc-disclaimer").removeClass("d-none");
		}

        var mystoreselectedesktop = $("#myStoreSelectedDesktop");
        var mystoreselectedmobile = $("#myStoreSelectedMobile");
		if (zipCode == undefined || zipCode == null) {
			$(".glyphicon-map-marker").hide();
			mystoreselectedesktop.hide();
			mystoreselectedmobile.hide();
		} else {
			$(".productDetailsZipCode").text(zipCode);
			$(".glyphicon-map-marker").show();
			maplocationzipdesktop.html(
				pricingForLabel.html()
			);
			mystoreselectedesktop.show();
			mystoreselectedmobile.show();
			if (geoSelected == "false" || geoSelected == "") {
				displayMyStore();
			} else if (source !== "UML") {
				$(".selected-zip-code").text(zipCode);
			}
			if (getCookieByName("makeMyStoreClicked") == "Yes") {
				mystoreselectedesktop.text(ACC.headerMyStore);
				mystoreselectedmobile.text(ACC.headerMyStore);
			} else if (getCookieByName("geolocationblocked") == "Yes") {
				mystoreselectedesktop.text($.storeCookie("zipCode"));
				mystoreselectedmobile.text($.storeCookie("zipCode"));
			}
		}
	}
}

function getCookied(source) {
    "use strict";
    var storeTown,
        storeRegionCode,
        latitudeStore,
        longitudeStore,
        streetNumberStore,
        streetNameStore,
        zipCode,
        phoneStore,
        storeDistance,
        storeTimings1Mon,
        storeTimings2Sat,
        storeTimings3Sun,
        fzTagline,
        posType;
    String.prototype.contains = function (it) {
        return this.indexOf(it) !== -1;
    };
 	storeData = $.storeCookieData();
	if (storeData.length != 0) {
		storeTown = storeData.town;
		zipCode = storeData.zipCode;
		fzTagline = storeData.fzTagline;
		latitudeStore = storeData.latitude;
		longitudeStore = storeData.longitude;
		streetNumberStore = storeData.streetNumber;
		streetNameStore = storeData.streetName;
		phoneStore = storeData.phone;
		storeDistance = storeData.distance;
		storeRegionCode = storeData.isoShortCode;
		posType = storeData.posType;
		storeTimings1Mon = storeData.storeTimings1;
		storeTimings2Sat = storeData.storeTimings2;
		storeTimings3Sun =storeData.storeTimings3;

        $("#myStoreText").text(ACC.myStore + storeTown + ", " + storeRegionCode);
        if (getCookieByName("DeliveryStoreAssigned") === "Yes") {
            $("#leadFormStoreInformationHeader")
                .empty()
                .append(
                    "<div>"
                        + formatStoreAddress(streetNameStore,
                                streetNumberStore, storeTown,
                                storeRegionCode,zipCode) + "</div><a href='tel:"
                        + phoneStore + "' title='tel:" + phoneStore
                        + "'>" + phoneStore + "</a>"); // Setting

			storeinformationrow.removeClass('mandatoryError');
   	 		storeinformationrow.css("border","none");
            if (finalClusterStoreDist != undefined && finalClusterStoreDist != null && finalClusterStoreDist != "") {
                if (getCookieByName("makeMyStoreClicked") == "Yes") {
                    $("#storeDistance")
                        .empty()
                        .append(
                            formatStoreDistance(storeDistance) +
                                " <div class='miles'>" + ACC.miles + "</div>"
                        );
                } else {
                    $("#storeDistance")
                        .empty()
                        .append(
                            formatStoreDistance(finalClusterStoreDist) +
                                " <div class='miles'>" + ACC.miles + "</div>"
                        );
                }
            } else if (storeDistance != undefined) {
                $("#storeDistance")
                    .empty()
                    .append(
                        formatStoreDistance(storeDistance) +
                            " <div class='miles'>" + ACC.miles + "</div>"
                    );
            }
        }

        $(".store-address-column").removeClass("mandatoryError");
        $(".store-address-column").css("border", "none");

		if (zipCode != undefined) {
			$(".selected-zip-code").text(zipCode);
			$("#zipCodeSelectedMobile").text(zipCode);
		}
    }

    $("#storePresent").show();
    $("#minessota_store").removeClass("display_pop_up");
    $("#minessota_store").addClass("no_display_pop_up");
    $(".map-shown").removeClass("display_pop_up");
    $(".map-shown").addClass("no_display_pop_up");
    $(".pop_up_cookie_saved").removeClass("no_display_pop_up");
    $(".pop_up_cookie_saved").addClass("display_pop_up");
    if (getCookieByName("DeliveryStoreAssigned") === "Yes") {
        $(".store-information .nostore-infoh").hide();
        $(".store-information .nostore-info").hide();
        $(".store-information .changestorebtn").show();
        $(".store-information .assignstorebtn").hide();
    }
    setCookiePopUp(
        storeDistance,
        phoneStore,
        streetNumberStore,
        streetNameStore,
        storeTown,
        storeRegionCode,
        zipCode,
        longitudeStore,
        latitudeStore,
        storeTimings1Mon,
        storeTimings2Sat,
        storeTimings3Sun,
        fzTagline
    );
    return "";
}

/***Function to initialize the map and call the pop up which appears when cookie is saved Start***/
function setCookiePopUp (passedSetCookieDistance, passedSetCookiePhone, passedSetCookiestoreNumber, passedSetCookiestoreName,
	passedSetCookiestoreTown, passedSetCookiestoreRegion, passedSetCookiestorePostalCode, passedSetCookieStoreTimings1,
	passedSetCookieStoreTimings2, passedSetCookieStoreTimings3,fzTagline) {
		
	"use strict";
	var passedSetCookieAddress = formatStoreAddress( passedSetCookiestoreName, passedSetCookiestoreNumber,
		passedSetCookiestoreTown, passedSetCookiestoreRegion, passedSetCookiestorePostalCode);

    var passedSetCookieAddress = formatStoreAddress(
        passedSetCookiestoreName,
        passedSetCookiestoreNumber,
        passedSetCookiestoreTown,
        passedSetCookiestoreRegion,
        passedSetCookiestorePostalCode
    );
    var storeTimings = formatStoreTimings(
        passedSetCookieStoreTimings1,
        passedSetCookieStoreTimings2,
        passedSetCookieStoreTimings3,
        fzTagline
    );

	var storeTimings = formatStoreTimings(passedSetCookieStoreTimings1, passedSetCookieStoreTimings2, passedSetCookieStoreTimings3, fzTagline);

	$(".pop_up_cookie_saved .distance").empty().append(passedSetCookieDistance);
	$(".pop_up_cookie_saved .store-results-address address")
		.empty()
		.append(passedSetCookieAddress);

	if (passedSetCookiePhone.indexOf("-") >= 0) {
	} else {
		passedSetCookiePhone =
			"(" +
			passedSetCookiePhone.substring(0, 3) +
			") " +
			passedSetCookiePhone.substring(3, 6) +
			"-" +
			passedSetCookiePhone.substring(6, 9);
	}

	$(".pop_up_cookie_saved .phone-number").attr(
		"href",
		"tel: " + passedSetCookiePhone
	);
	$(".pop_up_cookie_saved .phone-number")
		.empty()
		.append(passedSetCookiePhone);
	$(".pop_up_cookie_saved span.timingShown").empty().append(storeTimings);
}

function enablePaypalVenmoBtn(data) {
    //start paypalvenmo enable if flag is true
    var paymentoptions = $(".paymentOptions");
    var clienttoken = $('#clientToken');
    var paypalbutton = $('#paypal-button');
    var dataretrived = (JSON.stringify(data));
    var payPalKey= JSON.parse(dataretrived).payPalEnabled;
    var venmoKey = JSON.parse(dataretrived).venmoEnabled;
    var braintreeMerchantAccountId = JSON.parse(dataretrived).braintreeMerchantAccountId != null ? JSON.parse(dataretrived).braintreeMerchantAccountId : "";
    var clientToken = JSON.parse(dataretrived).clientToken != null ? JSON.parse(dataretrived).clientToken : "";
    if ( (venmoKey === true) || (payPalKey === true) ){
        paymentoptions.show();
        $(".securecheckoutcontent").css("padding-top","10px"); 
    }
    else{
        paymentoptions.hide();
    }

    if (clienttoken.length)
    {
        if (clienttoken.val() !== clientToken) {
            clienttoken.val(clientToken);
            paypalbutton.empty();
            
          if((paypalbutton.data( "merchid" ) === "") || (paypalbutton.data( "merchid" ) !== braintreeMerchantAccountId)){
             enablePaypalVenmo(braintreeMerchantAccountId,clientToken);
             paypalbutton.attr("data-merchid",braintreeMerchantAccountId )
           }
        }
        $(".paypalUserInfo").css("display","none"); 
        $(".venmoUserInfo").css("display","none");
    }
}

/** *Defining action for make my store button in pop up start** */
function makeMyStore(myStoreNumber, myStoreDistance, myStoreAddress, mystoreChangeStoreEvent, priorStoreNum, priorStoreZip) {

    "use strict";
    $(".return-to-map").addClass("hide");
    var isGeoBased = getCookieByName("assignByGeo");
	var makeMyStoreURL = "/find-a-store/makemystore?isGeoBased=" + isGeoBased + "&storeNumber=" + myStoreNumber + "&distance=" + myStoreDistance;
    $.ajax({
        url: makeMyStoreURL,
        type: "get",
        contentType: "application/json",
        success: function (data) {
        	window.dataLayer = window.dataLayer || [];
            var zipCode = data.address && data.address.postalCode;
            zipCode = zipCode && zipCode.replace(/-\d+$/,'');
            var storeId = data.storeNumber;
			var singlePage = $("#modelSinglePage").val();
			if(singlePage !== undefined && singlePage == "true" ) {
				var reservationFlagData = data.reservationFlagData;
				var isReservationEnabled = reservationFlagData.isReservationEnabled;
				var isPromoEnabled = reservationFlagData.isClaimOfferEnabled;
				$('input[name=hidden_identityVerification]').val(data.identityVerification);
				$('input[name=hidden_deEnabled]').val(data.decisionEngine);
				if(isReservationEnabled !== undefined && isPromoEnabled !== undefined) {
					checkAndUpdateFlows(isReservationEnabled.toString(), isPromoEnabled.toString(), data.decisionEngine);
				}
				if(reservationFlagData !== undefined && reservationFlagData.inventoryNumber !== undefined) {
					$('input[id=inventory-id]').val(reservationFlagData.inventoryNumber);
				}
			}
        	window.dataLayer.push({
                'event': 'store-locator-selection',
                'storeZip': zipCode,
                'storeId': storeId
        	});

        	window.dataLayer.push({
	            'event':'manual-store-change',
	            'type': mystoreChangeStoreEvent,
	            'priorStoreID':priorStoreNum,
	            'priorStoreZip':priorStoreZip,
	            'newStoreID':storeId,
	            'newStoreZip':zipCode
         	});
        	//start paypalvenmo enable if flag is true
            enablePaypalVenmoBtn(data);
      	    jQuery(".rac-spinner-wrapper").hide();
            var cookieVal = document.cookie;
            getCookied(cookieVal);
            $("#storeLocaterModal").modal("hide");
            if (localStorage.getItem("makeMyStoreClicked") == "Yes") {
                localStorage.setItem("makeMyStoreClicked", "Yes");
                displayMyStore();
                if (localStorage.getItem("changeStoreClicked") == "Yes") {
                    window.location.href = "#storePresent";
                    localStorage.setItem("changeStoreClicked", "No");
                }
            } else {
                displayGeoZip();
            }
			var currentUrl = window.location.href;
			if (currentUrl.indexOf($("#submitApplication").val()) < 0) {
				if ((currentUrl.indexOf($("#signUpForDeals").val()) > 0) || (currentUrl.indexOf($("#unsubscribe").val()) > 0)) {
					jQuery(".rac-spinner-wrapper").hide();
				}
				else if ((currentUrl.indexOf($("#contactUs").val()) > 0)) {
					setStoreAddrUpdate(data);
				}
				else if ($("#isSuperPlp").val() == "true") {
					//redirecting to super PLP default page on store change
					location.reload();
				}
				else {
					const params = new URLSearchParams(window.location.search)
					if (params.has('text')) {
						var text = params.get('text');
						var targetURL = window.location.href.split("?")[0];
						if (text !== '') {
							targetURL = targetURL + "?text=" + text;
						}
						window.location.href = targetURL;
					}
					else if (params.has('page')) {
						modifyPageParam();
					}
					else {
						location.reload();
					}
				}
			}
			
            if (leadMakeStoreAssigned == true && !( $("#modelSinglePage").val() == "true")) {
                if (submitContactInfo == true) {
                    submitForm1Information("true");
                } else {
                    submitForm1Information("false"); // Continue Flow
                }
            }

        },
        error: function (error) {
            console.log(error);
        },
    });
}

function modifyPageParam(){
	var url = new URL(document.URL);
	var search_params = url.searchParams;
	search_params.delete("page");
	url.search = search_params.toString();
	window.location.href = url;
}

/***Defining action for make my store button in pop up end***/
function displayGeoZip(source) {
    maplocationzipdesktop.html(pricingForLabel.html());
    $(".selected-zip-code").text($.storeCookie("zipCode"));
    $("#zipCodeSelectedMobile").text($.storeCookie("zipCode"));
    var geoSelected = getCookieByName("geolocationselected");
    displayHeaderStoreInfo(geoSelected, source);
	var currentUrl = window.location.href;
    var queryStrings = ["submit-application", "payfone-input-form","identity-verification-form","payment-schedule","form-submit-application","find-a-store"];
    checkIfAllQueryStringsExist(currentUrl, queryStrings);
    $("#zipPadding").remove();
	if( ($("body").hasClass("page-racProductDetailPage") == true) || ($("body").hasClass("pageType-CategoryPage") == true) ) {
		location.reload();
	};
}

function checkIfAllQueryStringsExist(url, qsCollection) {
	var weborderurl = false;
	for (var i = 0; i < qsCollection.length; i++) {
		if (url.indexOf(qsCollection[i]) != -1) {
			weborderurl = true;
		}
	}  
	return false;
}

/*Format Store Timings*/
function formatStoreTimings(timings1, timings2, timings3, fzTagline) {
    var tmpl = "<br><br>";
    timings3 ? (tmpl += timings3 + ",<br>") : tmpl;
    timings2 ? (tmpl += timings2) : tmpl;
    timings1 ? (tmpl += ", " + timings1) : tmpl;
    if (fzTagline == "true") {
        tmpl += "<br>" + ACC.locallyOwnedOperated;
    }
    return tmpl;
}

/*Format Store Timings*/
function formatStoreAddress(streetName, streetNumber, town, isoCode, zip) {
    var tmpl = "";
    streetNumber ? (tmpl += streetNumber) : tmpl;
    streetName ? (tmpl += " " + "<br>" +streetName + ", ") : tmpl;
    town
        ? streetNumber && !streetName
            ? (tmpl += "<br>" + town + ", ")
            : (tmpl += town + ", ")
        : tmpl;
    isoCode ? (tmpl += isoCode + ", ") : tmpl;
    zip ? (tmpl += zip) : tmpl;
    return tmpl;
}

function loadScript(url, callback) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;

    if (script.readyState) {
        //IE
        script.onreadystatechange = function () {
            if (
                script.readyState == "loaded" ||
                script.readyState == "complete"
            ) {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        //Others
        script.onload = function () {
            callback();
        };
    }

    script.src = url;
    script.id = "racGoogleAPI";
    document.getElementsByTagName("head")[0].appendChild(script);
}

function displayMyStore() {
    var currentUrl = window.location.href;
    var isBlocked = getCookieByName("geolocationblocked");
    var geoSelected = getCookieByName("geolocationselected");
    if (currentUrl.indexOf("submit-application") != -1) {
        $("#nearestStoreModal").hide();
    }
    $(".selected-zip-code").text($.storeCookie("zipCode"));
    $("#mapLocationMyStoreDesktop").show();
    $("#zipPadding").remove();
    if (isBlocked !== "No") {
        setCookie("geoFirstTime", "Yes", 7, "", true);
        setCookie("geolocationblocked", "Yes", 7, "", true);
    }
    if (geoSelected == "Yes") {
        setCookie("geoFirstTime", "Yes", 7, "", true);
        setCookie("geolocationselected", "Yes", 7, "", true);
    }
}

function assignStoreBtn() {
    $(window).scrollTop(0);
    deliveryStoreSelection = false;
    if ((document.getElementById("formSection1").style.display == "none") || ($("#modelSinglePage").val() == "true")) {
        setCookie("assignbuttonclicked", "Yes", 7, "", true);
        setCookie("popUpTrigger", "ChangeStore", 7, "", true);
        setTheStoreLocatorModalToDefault();
        storeLocaterModal.modal("show");
    }
}
function validateHeaderZipCode() {
    var zipCodeChk = $('#enterZipCode_Nav').val();
    var zipCodeRegex = /^\d{5}$/;
    if (zipCodeRegex.test(zipCodeChk)) {
    	return true;
    } else {
    	displayInvalidZipError();
    	return false;
    }
}

function displayInvalidZipError() {
	storeLocatorFormZipCode.addClass("error");
    $(".store-locator .form-input-zip-section .invalid-zipcode").show();

    if ($("#storeLocaterModal  .showAvailableStore .unavailable-store").is(":visible")) {
        $(".showAvailableStore").hide();
    }

    if ($("#storeLocaterModal  .searchnostorefound").is(":visible")) {
        $(".searchnostorefound").hide();
    }
    storeLocaterModal.modal("show");
}

function openPopup(zipCode, storeChangeEvent) {
    if(storeChangeEvent == null) {
        storeChangeEvent = "";
    }
    openPopupSource = "";

    if (typeof zipCode === "undefined" || zipCode === null) {
        openPopupSource = "changeStore";
        zipCode = $.storeCookie("zipCode");
        setCookie("popUpTrigger", "ChangeStore", 7, "", true);
    }
	
	var _localStoreNum = $.storeCookie("storeNumber");
    var skipStore = $(this)
        .closest("div.storeContainerInputForm")
        .children(".skipSelectAStore");
    $(".store-locator .form-input-zip-section .invalid-zipcode").hide();
    $(".store-locator .form-input-zip-section .unable-to-locate").hide();
        storeLocatorFormZipCode.removeClass("error");
        $.ajax({
            url: "/find-a-store/postalcode?postalcode=" + zipCode,
            type: "get",
            contentType: "application/json",
            success: function (data) {
            	window.dataLayer = window.dataLayer || [];
            	window.dataLayer.push({
            		'event': 'store-locator-search',
            		'storeZip': zipCode
            	});
                var object = JSON.parse(data);
                poslist = object;
                skipStore.hide();
                $(".skipSelectAStore").hide();
                var errorMessage=JSON.parse(data).errorMessage;
                if (errorMessage != undefined && errorMessage != "") {
                    $(".store-locator .form-control.enter-zip-code").val(zipCode);
                    $(".searchnostorefound").hide();
                    $(".showAvailableStore").hide();
                    storeLocaterModal.find(".-or-").hide();
                    storeLocaterModal.find(".use-my-location").hide();
                    $(".store-locator .content").addClass("padding");
                    $(".store-locator .modal-body").addClass(
                        "store-preview-padding"
                    );
                    /** Showing Minnesota and Wisconsin store **/
                    var unavailabelStore;
                    var dummyErrorResponsejQobj = $(
                        $.parseHTML(object.errorMessage)
                    );

                    if (dummyErrorResponsejQobj.find("img").length > 0) {
                        var imgSrcToChange =
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            dummyErrorResponsejQobj
                                .find("img")
                                .attr("src")
                                .split("..")[1];
                        dummyErrorResponsejQobj
                            .find("img")
                            .attr("src", imgSrcToChange);
                        unavailabelStore = dummyErrorResponsejQobj
                            .wrap("<p/>")
                            .parent()
                            .html();
                        $(".showAvailableStore")
                            .empty()
                            .append(
                                '<p class="error-txt-no-store">' +
                                    unavailabelStore +
                                    "</p>"
                            )
                            .clone();
                        $(".showAvailableStore").show();
                        $(".searchnostorefound").hide();
                    } else {
                        $(".showAvailableStore")
                            .empty()
                            .append(
                                $(".searchnostorefound")
                                    .find(".searchnostorefoundtext")
                                    .html()
                            )
                            .clone();
                        $(".unavailable-store").show();
                        wlsinglepage = false;
                        singlepagestorelocatorzipcodeerror = true;

                        $(".showAvailableStore").show();
                        $(".searchnostorefound").hide();
                        if (submitContactInfo == true && noStoreFlag == true) {
                            NSFMyStore = true;
                        }
                    }

                    $("#stores-available").hide();
                    $(".store-map").hide();

                    var mkp = "",
                        distance = 0,
                        mapData = [];
                    mapData.push({
                        id: 1,
                        name: "nostore",
                        address: zipCode,
                        distance: distance,
                    });

                    $(".store-locator-popup-content").html(
                        $("<ul/>").html(mkp)
                    );
                    $(
                        ".storeContainerInputForm .form-input-zip-section"
                    ).show();
                    $(
                        ".storeContainerInputForm .form-input-zip-section"
                    ).removeClass("form-input-zip-overlay");
                    $(
                        ".storeContainerInputForm .selected-zip-container"
                    ).addClass("hide");
                    $(".view-stores-near-zipcode").html(
                        $("#viewstoresnearlocailizedheader").data(
                            "enterzipcode"
                        )
                    );
                    $(".toggle-search-zip").removeClass("active");
                    $(".store-map").each(function () {
                        updateMap(this, mapData);
                        $(".showAvailableStore").hide();
                    });

                    $(".store-locator-spinner").modal("hide");
                    jQuery(".rac-spinner-wrapper").hide();
                    storeLocaterModal.modal("show");
                } else {
                    /** Showing available store **/
                    $(".store-locator .content").removeClass("padding");

                    var stores = JSON.parse(data).posDataList;
                    var mkp = "",
                        distance = 0,
                        mapData = [];

                    $(".view-stores-near-zipcode").html(
                        $("#viewstoresnearlocailizedheader").data(
                            "displaystoresnear"
                        )
                    );
                    $(".view-stores-near-zipcode").show();
                    $(".searchnostorefound").hide();
                    $(".unavailable-store").hide();
                    $(".showAvailableStore").show();
                    $(".store-locator .modal-body").removeClass(
                        "store-preview-padding"
                    );
                    $(
                        ".storeContainerInputForm .form-input-zip-section"
                    ).hide();
                    $(
                        ".storeContainerInputForm .form-input-zip-section"
                    ).removeClass("form-input-zip-overlay");

                    var storeResultContainer =
                        '<div class="store-map body.noScroll" id="store-map"></div><div id="stores-available" class="store-locator-popup-content" style="display: block;"></div><div class="store-preview"></div>';
                    $(".showAvailableStore")
                        .empty()
                        .append(storeResultContainer);
                    var currentUrl;
                    $.each(stores, function (k, v) {
                        $(".searchnostorefound").hide();
                        distance = v.formattedDistance.split(" ")[0];
                        var franchise = "";
                        if (v.fzTagline == true) {
                            franchise = "Locally owned and operated";
                        }

                        var storeaddr = "";
                        if (
                            v.address.streetNumber &&
                            v.address.town &&
                            v.address.region.name &&
                            v.address.postalCode
                        ) {
                            storeaddr =
                                v.address.streetNumber.trim() +
                                ", " +
                                v.address.town +
                                ", " +
                                v.address.region.name +
                                ", " +
                                v.address.postalCode.substring(0, 5);
                        }

                        var mapPinImage;

                        if (_localStoreNum == v.storeNumber) {
                            currentUrl = window.location.href;
                            if (currentUrl.indexOf("submit-application") != -1 && (getCookieByName("DeliveryStoreAssigned") == "No" || getCookieByName("DeliveryStoreAssigned") == "")) {
                            	mapPinImage = "_blue";
                            } else {
                            mapPinImage = "_green";
                            }
                        } else {
                            mapPinImage = "_blue";
                        }
                        var storeHours = "";
                        if (v.openingHours != null) {
                            var weekdayOpeningList = v.openingHours.weekDayOpeningList;
                            if(weekdayOpeningList != null) {
	                            weekdayOpeningList.push(weekdayOpeningList.shift());
	                            $.each(weekdayOpeningList, function(_weekDayIndex, weekDay) {
	                                if (weekDay.weekDayName != null) {
	                                    storeHours =  storeHours + "<div class='hours-section'> <span class='week_days font14 bold'>" +  ACC[weekDay.weekDayName];
	                                    storeHours =  storeHours + "</span><span class='hours_opening font14'>";
	                                    if (weekDay.closed) {
	                                        storeHours = storeHours + ACC.storeClosed + "</span></div>";
	                                    } else {
	                                        storeHours = storeHours + weekDay.openingTime.formattedHour + " - " +
	                                            weekDay.closingTime.formattedHour + "</span></div>";
	                                    }
	                                }
	                            });
                            }
                        }

                        mkp +=
                            '<li class="col-xs-12 clearfix map-item"><span class="col-xs-1 store-distance"><div class="point" style="background-image: url(/_ui/responsive/common/images/' +
                            (k + 1) +
                            mapPinImage +
                            '.svg);"><div class="distance">' +
                            distance +
                            'mi</div></div></span><span class="col-xs-10 store-details" data-address="' +
                            storeaddr +
                            '" data-phone="' +
                            v.address.phone +
                            '" data-franchise="' +
                            franchise +
                            '" data-distance="' +
                            distance +
                            '" data-timings="' +
                            storeHours +
                            '" data-lat="' +
                            v.geoPoint.latitude +
                            '" data-lng="' +
                            v.geoPoint.longitude +
                            '"><div class="storeAddress">' +
                            storeaddr +
                            "</div>";

                        var assignclick = getCookieByName(
                            "assignbuttonclicked"
                        );

                        if (assignclick == "Yes") {
                            mkp +=
                                '<button class="makemystore makeMyStorebtn1 btn btn-default blue font16 fontMob14"  data-storenum="' +
                                v.storeNumber +
                                '"data-storechangeevent="' +
                                storeChangeEvent +
                                '" data-distance="' +
                                v.formattedDistance +
                                '" >'+ 	ACC.makeMyStore +'<img src=/_ui/responsive/common/images/RAC-iconImages/Button-arrow-white.svg /></button>';
                        } else {
                            if (_localStoreNum == v.storeNumber) {
                            	currentUrl = window.location.href;
                                if (currentUrl.indexOf("submit-application") != -1 && (getCookieByName("DeliveryStoreAssigned") == "No" || getCookieByName("DeliveryStoreAssigned") == "")) {
                                	mkp +=
                                        '<button class="makemystore makeMyStorebtn1 btn btn-default blue font16 fontMob14"  data-storenum="' +
                                        v.storeNumber +
                                        '"data-storechangeevent="' +
                                        storeChangeEvent +
                                        '" data-distance="' +
                                        v.formattedDistance +
                                        '" >'+ 	ACC.makeMyStore +' <img src=/_ui/responsive/common/images/RAC-iconImages/Button-arrow-white.svg /></button>';
                                } else {
                                	mkp +=
                                        '<button class="myPreferredStore makeMyStorebtn1 btn btn-default blue font16 fontMob14"  data-storenum="' +
                                        v.storeNumber +
                                        '"data-storechangeevent="' +
                                        storeChangeEvent +
                                        '" data-distance="' +
                                        v.formattedDistance +
                                        '" >'+ ACC.headerMyStore+ '</button>';
                                }
                            } else {
                                mkp +=
                                    '<button class="makemystore makeMyStorebtn1 btn btn-default blue font16 fontMob14"  data-storenum="' +
                                    v.storeNumber +
                                    '"data-storechangeevent="' +
                                    storeChangeEvent +
                                    '" data-distance="' +
                                    v.formattedDistance +
                                    '" >'+ 	ACC.makeMyStore +'<img src=/_ui/responsive/common/images/RAC-iconImages/Button-arrow-white.svg /></button>';
                            }
                        }

                        mkp +=
                            '</span><span class="col-xs-1 pull-right store-info"><div class="maps-info-icon"><img src=/_ui/responsive/common/images/RAC-iconImages/Info.svg /><div class="maps-info-text">'+ ACC.infoLbl +'</div></span></div></li>';
                        mapData.push({
                            id: v.storeNumber,
                            name: v.name,
                            address:
                                v.address.streetNumber.trim() +
                                ", " +
                                v.address.town +
                                ", " +
                                v.address.region.name +
                                ", " +
                                v.address.postalCode.substring(0, 5),
                            distance: distance,
                            lat: v.geoPoint.latitude,
                            lng: v.geoPoint.longitude,
                        });
                    });

                    $(".store-locator-popup-content").html(
                        $("<ul/>").html(mkp)
                    );
                    $(
                        ".storeContainerInputForm .selected-zip-container"
                    ).removeClass("hide");
                    $(
                        ".storeContainerInputForm .selected-zip-container .selected-zip"
                    ).text(zipCode);
                    $(
                        ".storeContainerInputForm .toggle-search-zip"
                    ).removeClass("active");
                    $(".hide-zip").hide();
                    $(".change-zip").show();
                    storeLocaterModal.find(".-or-").show();
                    storeLocaterModal.find(".use-my-location").show();
                    $(".store-map").each(function () {
                        updateMap(this, mapData);
                        $(".showAvailableStore").show();
                    });
                    storeChangeWarningToggler();
                    jQuery(".rac-spinner-wrapper").hide();
                    $(".return-to-map").addClass("hide");
                    storeLocaterModal.modal("show");

                    // below snippet to fix the safari map issue
                    $("#store-map").css("width", "101%");
                    var fn = function () {
                        $("#store-map").css("width", "100%");
                    };
                    setTimeout(fn, 300);
                }
            },
            error: function (error) {
                console.log(error);
            },
        });
}

function updateMap(elem, stores) {
    $("body").css("cursor", "progress");

    var sydney = {
        lat: 33.0297853,
        lng: -96.6845102,
    };

    var map = new google.maps.Map(elem, {
        center: sydney,
        zoom: 11,
        mapTypeId: "roadmap",
        mapTypeControl: true,
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: true,
    });
 	var _localStoreNum = $.storeCookie("storeNumber");
    var infoWindow = new google.maps.InfoWindow();
    var markers = [];
    var bounds = new google.maps.LatLngBounds();

    for (var i = 0; i < stores.length; i++) {
        var name = stores[i].name;
        var address = stores[i].address;
        var latlng = new google.maps.LatLng(
            parseFloat(stores[i].lat),
            parseFloat(stores[i].lng)
        );
        var html = "<b>" + name + "</b> <br/>" + address;
        var imagePath;
        if (_localStoreNum == stores[i].id) {
            imagePath =
                "/_ui/responsive/common/images/" + (i + 1) + "_green.svg";
        } else {
            imagePath =
                "/_ui/responsive/common/images/" + (i + 1) + "_blue.svg";
        }

        var image = new google.maps.MarkerImage(
            imagePath,
            null,
            null,
            null,
            new google.maps.Size(20, 30)
        );

        var marker = new google.maps.Marker({
            map: map,
            position: latlng,
            icon: image,
        });

        (function (marker, map, html, infoWindow) {
            google.maps.event.addListener(marker, "click", function () {
                infoWindow.setContent(html);
                infoWindow.open(map, marker);
            });
        })(marker, map, html, infoWindow);
        markers.push(marker);
        bounds.extend(latlng);
    }
    map.fitBounds(bounds);
    setTimeout(function () {
        map.setZoom(9);
    }, 1000);

    $("body").css("cursor", "default");
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function popupCloseBtn() {
    nearestStorePopulate();
}

function noStoreFlow() {
    setCookie("DeliveryStoreAssigned", "No", 7, "", true);
    $("#leadFormStoreInformationHeader").empty().append("");
    $("#storeDistance").empty().append("");
    $(".store-information .changestorebtn").hide();
    $(".store-information .assignstorebtn").show();
    $(".store-information .nostore-infoh").show();
    $(".store-information .nostore-info").show();
    var isBlocked = getCookieByName("geolocationblocked");

    if ((getCookieByName("updateBtn") === "Yes" && isBlocked !== "Yes") ||
        (getCookieByName("ContinueBtn") === "Yes" && isBlocked !== "Yes") ||
        (getCookieByName("upperFormSubmitBtn") === "Yes" && isBlocked !== "Yes")) {
        updatecluster();
        $("#step2SubmitBtn").removeAttr("disabled");
        setCookie("nostoreset", "Yes", 7, "", true);
    } else if (getCookieByName("upperFormSubmitBtn") === "Yes" && isBlocked !== "Yes") {
        submittingForm();
    } else if (
        (getCookieByName("ContinueBtn") === "Yes" ||
            $("#modelSinglePage").val() == "true" ||
            getCookieByName("upperFormSubmitBtn") === "Yes" ||
            getCookieByName("updateBtn") === "Yes")) {
        zipcodeFetch = parseInt($("#zipcodeTI").val());
            if (zipcodeFetch != null) {
                //postalcode call
                $.ajax({
                    url: "/find-a-store/postalcode?postalcode=" + zipcodeFetch,
                    type: "get",
                    contentType: "application/json",
                    success: function (data) {
                        var object = JSON.parse(data);
                        posList = object;
                        if (Object.keys(object) != "errorMessage") {
                            assignStore();
                            $(".store-information .nostore-infoh").css(
                                "display",
                                "none"
                            );
                            setCookie(
                                "DeliveryStoreAssigned",
                                "Yes",
                                7,
                                "",
                                true
                            );
                            $(".store-information .nostore-info").css(
                                "display",
                                "none"
                            );
                            $(".store-information .changestorebtn").css(
                                "display",
                                "block"
                            );
                            $(".store-information .assignstorebtn").css(
                                "display",
                                "none"
                            );
                        } else {
                            jQuery(".rac-spinner-wrapper").hide();
                            leadMakeStoreAssigned = true;
                            deliverAddrZip = $("#zipcodeTI").val();
                            openPopup(deliverAddrZip);
                        }
                    },
                });
            }

            $("#step2SubmitBtn").removeAttr("disabled");
        setCookie("nostoreset", "Yes", 7, "", true);
    }
}

// This function triggers on use-my-location button click from the store location pop up.
$("body").on("click", ".use-my-location", function (e) {
    var geoLocationBlocked = getCookieByName("geolocationblocked");
    if (storeLocaterModal.hasClass("in")) {
        storeLocatorFormZipCode.removeClass("error");
        $(
            ".store-locator .form-input-zip-section .invalid-zipcode, .store-locator .form-input-zip-section .unable-to-locate"
        ).hide();
    } else {
        $(".store-finder form.zipcode").removeClass("error");
        $(
            ".store-finder .form-input-zip-section .invalid-zipcode, .store-finder .form-input-zip-section .unable-to-locate"
        ).hide();
    }

    if (
        geoLocationBlocked === "undefined" ||
        geoLocationBlocked === "" ||
        geoLocationBlocked === "No"
    ) {
        getCurrentLocation();
    } else {
        handleLocationErrorOnUMLClick();
    }
});

function getCurrentLocation() {
    if (navigator.geolocation) {
        var locationOptions = {
            enableHighAccuracy: true,
            maximumAge: 30000,
            timeout: 10000,
        };
        //passing the source of action value to the getPosition to handle the logic related the specific button click, here it is UseMyLocation. UML -stands for use my location
        navigator.geolocation.getCurrentPosition(
            function (position) {
                getPositionOnUMLClick(position);
            },
            handleLocationErrorOnUMLClick,
            locationOptions
        );
    }
}

function setTheStoreLocatorModalToDefault() {
    // this is to default the store local modal pop-up
    $(".store-locator .content").addClass("padding");
    $(".return-to-map").addClass("hide");
    $(".storeContainerInputForm .form-input-zip-section").removeClass(
        "form-input-zip-overlay"
    );
    $(".storeContainerInputForm .form-input-zip-section").show();
    $(".view-stores-near-zipcode").show();
    $(".store-locator .modal-title").text(
        $("#storelocatorlocalizeddata").data("storelocatorheader")
    );
    storeLocaterModal.find(".-or-").show();
    storeLocaterModal.find(".use-my-location").show();
    $(".storeContainerInputForm .selected-zip-container").addClass("hide");
    $(".storeChangeWarning").hide();
    $(".showAvailableStore").hide();
    $(".searchnostorefound").hide();
    $("#stores-available").hide();
    $(".store-map").hide();
    $(".view-stores-near-zipcode").html(
        $("#viewstoresnearlocailizedheader").data("enterzipcode")
    );
    $(".storeContainerInputForm .enter-zip-code").val("");
    storeLocatorFormZipCode.removeClass("error");
    $(".store-locator .form-input-zip-section .invalid-zipcode").hide();
    $(".store-locator .form-input-zip-section .unable-to-locate").hide();
}

function getPositionOnUMLClick(position) {
    var curLat = position.coords.latitude,
        curLong = position.coords.longitude;

    var postlaCodeURL =
        "/find-a-store/postalcode?latitude=" + curLat + "&longitude=" + curLong;
    $.ajax({
        url: postlaCodeURL,
        type: "get",
        contentType: "application/json",
        success: function (data) {
            var object = JSON.parse(data);

            if (Object.keys(object) != "errorMessage") {
                if (
                    object["posDataList"][0] != null &&
                    object["posDataList"][0].address != null &&
                    object["posDataList"][0].address.postalCode != null
                ) {
                    openPopup(
                        object["posDataList"][0].address.postalCode.substring(
                            0,
                            5
                        ),"geolocation"
                    );
                } else {
                    handleLocationErrorOnUMLClick();
                }
            } else {
                handleLocationErrorOnUMLClick();
            }
        },
        error: function (error) {
            console.log(error); //server error
        },
    });
}

function handleLocationErrorOnUMLClick(error) {
    if (error != null) {
        console.log("error code" + error.code);
    }
    if (storeLocaterModal.hasClass("in")) {
        storeLocatorFormZipCode.addClass("error");
        $(".store-locator .form-input-zip-section .unable-to-locate").show();
    } else {
        $(".store-finder form.zipcode").addClass("error");
        $(".store-finder .form-input-zip-section .unable-to-locate").show();
    }
}

function displaySubmitContactInfoLink() {
	if ($("#modelSinglePage").val() == "true") {
        if (DeliveryStoreSelection === false) {
            $("#step1UpperFormSubmitOnePage").hide();
            $(".singlePagelegalText").hide();
            $(".onepage-justcontact").hide();
        } else {
            $("#step1UpperFormSubmitOnePage").show();
            $(".singlePagelegalText").show();
            $(".onepage-justcontact").show();
        }
    if ($.storeCookie("zipCode") && (getCookieByName("DeliveryStoreAssigned") ||
      getCookieByName("DeliveryStoreAssigned") === "Yes")) {
      DeliveryStoreSelection = false;
      }
    }
}

/*Format Store Timings*/
function formatStoreDistance(distance) {
	if (distance) {
	    var splitItems = distance.toString().split(' ');
	    return splitItems[0] ;
    }
}

function checkAndUpdateFlows(isReservationEnabled, isPromoEnabled, deEnabled) {
    hideReservationFlow();
    hideNoPromoNoReservationFlow();
    updatePricing();
    updateFormTargetForPayfone();
    var isPreApproval = $('input:hidden[name=preApprovalFlow]').val() === 'true';
    if (isRACStore()) {
        if ((isReservationEnabled === 'true') || isPromoEnabled === 'true') {
            showReservationFlow();
        } else {
            showNoPromoNoReservationFlow();
        }
    }
    else if(isPreApproval) {
        if (deEnabled || $('#hidden_identityVerification').val() === 'true') {
            showReservationFlow();
        } else {
            showNoPromoNoReservationFlow();
        }
    }
    else {
        if (isPromoEnabled === 'true') {
            showReservationFlow();
        } else {
            showNoPromoNoReservationFlow();
        }
    }
    updateLegalText(isReservationEnabled, isPromoEnabled, isPreApproval);
}

function updateFormTargetForPayfone() {
	var selectedPayfoneFlow = $('#contactInformationFormData').find('input[name="selectedPayfoneFlow"]').val();
    var isPreApproval = $('input:hidden[name=preApprovalFlow]').val() === 'true';
	if ($('#hidden_identityVerification').val() === 'true' && selectedPayfoneFlow == 'false' && ($('input[name=hidden_hasDecision]').val() !== 'true' || $('input[name=hidden_visitedIDVPage]').val() == 'true')) {
		$('form[id=contactInformationFormData]').attr('action', $('input[name=hidden_targetIdentityVerification]').val());
	}
	else if ($('input[name=hidden_deEnabled]').val() === 'true' && (selectedPayfoneFlow == 'true' || $('input[name=hidden_hasDecision]').val() === "true" || $('input[name=hidden_skipIDVForm]').val() == "true")) {
		$('form[id=contactInformationFormData]').attr('action', $('input[name=hidden_targetPaySchedule]').val());
	}
	else {
		$('form[id=contactInformationFormData]').attr('action', $('input[name=hidden_targetPayment]').val());
	}
}

function isRACStore() {
    return $.storeCookie("postype") !== "High Touch";
}

function updatePricing() {
		$.ajax({
			url: '/get-pricing',
			type: 'GET',
			async: false,
			success: function(response) {
				if (response != null && $.isNumeric(response.terms) && $.isNumeric(response.pricePerTerm)
					&& $.isNumeric(response.totalCost) && $.isNumeric(response.cashPrice)) {
					if (response.isBundleProduct) {
					    if ($.isNumeric(response.discountPercentage) && $.isNumeric(response.bundleTotalPrice)
							&& $.isNumeric(response.bundleStrikeThroughPrice)) {
							if ($('.js-bundle-pricing .js-cash-price .js-pricing-data').length) {
								$('.js-bundle-pricing .js-cash-price .js-pricing-data').text(formatPrice(response.cashPrice));
							}
							if ($('.js-bundle-pricing .js-total-cost .js-pricing-data').length) {
								$('.js-bundle-pricing .js-total-cost .js-pricing-data').text(formatPrice(response.totalCost));
							}
							if ($('.js-bundle-pricing .numpaymentinfo').length) {
								$('.js-bundle-pricing .numpaymentinfo').text(Number(response.terms).toFixed(0));
							}
							if ($('.js-bundle-pricing .renewal-price .js-pricing-data').length) {
								$('.js-bundle-pricing .renewal-price .js-pricing-data').text(formatPrice(response.bundleTotalPrice));
							}
							if ($('.js-bundle-pricing .renewal-strike-price .js-pricing-data').length) {
								$('.js-bundle-pricing .renewal-strike-price .js-pricing-data').text(formatPrice(response.bundleStrikeThroughPrice));
							}
							if ($('.js-bundle-pricing .discount .js-pricing-data').length) {
								$('.js-bundle-pricing .discount .js-pricing-data').text(formatPrice(response.discount));
							}
							if ($('.js-bundle-pricing .js-inventory-type').length) {
								$('.js-bundle-pricing .js-inventory-type').text(response.inventoryType);
							}
						}	
					} else {
					    if ($('.js-price-per-term .js-pricing-data').length) {
							$('.js-price-per-term .js-pricing-data').text(formatPrice(response.pricePerTerm));
						}
						if ($('.js-product-pricing .js-total-terms').length) {
							$('.js-product-pricing .js-total-terms').text(Number(response.terms).toFixed(0));
						}
						if ($('.js-product-pricing .js-total-cost .js-pricing-data').length) {
							$('.js-product-pricing .js-total-cost .js-pricing-data').text(formatPrice(response.totalCost));
						}
						if ($('.js-product-pricing .js-cash-price .js-pricing-data').length) {
							$('.js-product-pricing .js-cash-price .js-pricing-data').text(formatPrice(response.cashPrice));
						}
                        if ($('.js-product-pricing .js-price-card').length) {
                            $('.js-product-pricing .js-price-card').text(formatPrice(response.pricePerTerm));
                        }
                        if (response.oldPrice != null) {
                            $('.js-product-pricing .js-old-price').removeClass('hide');
                            $('.js-product-pricing .js-old-price .js-pricing-data').text(formatPrice(response.oldPrice));
                            $('.js-product-pricing .js-price-card').addClass('font-bold');
                        } else {
                            $('.js-product-pricing .js-old-price').addClass('hide');
                            $('.js-product-pricing .js-price-card').removeClass('font-bold');
                        }
                        if (response.oldPrice != null && response.priceTagType != null) {
                            $('.checkout-flow-card .old-price').removeClass('hide');
                            $('.checkout-flow-card .old-price .js-pricing-data').text(formatPrice(response.oldPrice));
                            $('.checkout-flow-card .js-price-per-term').addClass('marginB0');
                            $('.checkout-flow-card .js-price-per-term').removeClass('marginB40');
                        } else {
                            $('.checkout-flow-card .old-price').addClass('hide');
                            $('.checkout-flow-card .js-price-per-term').removeClass('marginB0');
                            $('.checkout-flow-card .js-price-per-term').addClass('marginB40');
                        }
                        if (response.oldTerms != null) {
                            $('.js-product-pricing .js-old-terms').removeClass('hide');
                            $('.js-product-pricing .js-old-terms').text(Number(response.oldTerms).toFixed(0));
                            $('.js-product-pricing .js-total-terms').addClass('font-bold');
                        } else {
                            $('.js-product-pricing .js-old-terms').addClass('hide');
                            $('.js-product-pricing .js-total-terms').removeClass('font-bold');
                        }
                        if (response.oldTotalCost != null) {
                            $('.js-product-pricing .js-old-total-cost').removeClass('hide');
                            $('.js-product-pricing .js-old-total-cost .js-pricing-data').text(formatPrice(response.oldTotalCost));
                            $('.js-product-pricing .js-total-cost').addClass('font-bold');
                        } else {
                            $('.js-product-pricing .js-old-total-cost').addClass('hide');
                            $('.js-product-pricing .js-total-cost').removeClass('font-bold');
                        }
                        if (response.oldCashPrice != null) {
                            $('.js-product-pricing .js-old-cash').removeClass('hide');
                            $('.js-product-pricing .js-old-cash .js-pricing-data').text(formatPrice(response.oldCashPrice));
                            $('.js-product-pricing .js-cash-price').addClass('font-bold');
                        } else {
                            $('.js-product-pricing .js-old-cash').addClass('hide');
                            $('.js-product-pricing .js-cash-price').removeClass('font-bold');
                        }
                        if (response.wasDiscountPercentage != null) {
                            $('.checkout-flow-card .off-value-box .was-percent-discount').text(response.wasDiscountPercentage);
                            $('.checkout-flow-card .off-value-box').removeClass('hide');
                        } else {
                            $('.checkout-flow-card .off-value-box').addClass('hide');
                        }
					}
				}

			},
			error: function() {
				console.log('ERROR : unable to fetch updated cart data');
			}
		});
}

function getPricingAjax(priceType, productCode, inventoryType, storeNum) {
	var result = null;
	$.ajax({
		url: '/get-pricing',
		type: 'GET',
		async: false,
		data: {priceType:priceType, productCode:productCode, inventoryType:inventoryType, storeNum:storeNum},
		success: function (response) {
			result = response;
		},
		error: function () {
			console.log('ERROR : unable to fetch pricing');
			result = null;
		}
	});
	return result;
}

function updateLegalText(isReservationEnabled, isPromoEnabled, isPreApproval) {
	var selectedPayfoneFlow = $('#contactInformationFormData').find('input[name="selectedPayfoneFlow"]').val();
	 var productCode = $('#contactInformationFormData').find('input[name="productCode"]').val();
	var reservationText = $("#reservationText");
	var promoText = $("#promoText");
	var reservationNPromoText = $("#reservationNPromoText");
	var noReservationNoPromoText = $("#noReservationNoPromoText");
	if ((isPreApproval === true && (selectedPayfoneFlow == 'false' && $('#hidden_identityVerification').val() === 'true'))
			|| (isPreApproval === false && (isReservationEnabled === 'true' || isPromoEnabled === 'true'))) {
		reservationText.css("display", "none");
		promoText.css("display", "none");
		reservationNPromoText.css("display", "block");
		noReservationNoPromoText.css("display", "none");
		$("#reservationNPromoTextEdit").css("display", "none");
	} else {
		reservationText.css("display", "none");
		promoText.css("display", "none");
		reservationNPromoText.css("display", "none");
		noReservationNoPromoText.css("display", "block");
		$("#reservationNPromoTextEdit").css("display", "none");
	}
}

function showReservationFlow() {
	$('#reservationContinueButton').show();
	$("#continueReserveButton").css("display", "block");
}

function hideReservationFlow() {
	$('#reservationContinueButton').hide();
	$("#continueReserveButton").css("display", "none");
}

function showNoPromoNoReservationFlow() {
	$("#noReserveFlow").show();
	$(".noReserveSubmit").css("display", "block");
}

function hideNoPromoNoReservationFlow() {
	$("#noReserveFlow").hide();
	$(".noReserveSubmit").css("display", "none");
}

function setStoreAddrUpdate(data) {
	var storeRegionCode, streetNumberStore, streetNameStore, storeTown, storeRegion, postalCodeStore, phoneStore, storeNum, storeDistance, storeTimings2Sat;
	    	$('.updateStoreAdr').removeClass("hidden");
	    	$('.findStoreAdr').addClass("hidden");
	    	$('.find-yourStore').addClass("hidden");
	        storeNum = data.storeNumber;
	        streetNumberStore = data.address.streetNumber;
	        streetNameStore = data.address.streetName;
	        storeTown = data.address.town;
	        storeRegion = data.address.region;
	        postalCodeStore = data.address.postalCode;
	        phoneStore = data.address.phone.toString();
	        if(phoneStore.length >= 10){
	        	var val1 = phoneStore.substring(0, 3);
		        var val2 = phoneStore.substring(3, 6);
		        var val3 = phoneStore.substring(6, phoneStore.length);
		        phoneStore = "(" + val1 + ")" + " " + val2 + "-" + val3;
	        }
	        else{
		        phoneStore = "";
	        }
	        storeRegionCode = storeRegion.isocodeShort;
	        storeDistance = data.distanceKm;   
		    storeTimings2Sat = data.storetimings2;
		    if (storeNum != undefined || storeNum != "") {
	            setCookie('DeliveryStoreAssigned', 'Yes');
	          	$('.updateStoreAdr').removeClass("hidden");
	        	$('.findStoreAdr').addClass("hidden");
	              if (phoneStore != undefined) {
	                $(".contactStoreInformationHeader").empty().append(
	                        "<div>"
	                                + formatStoreAddress(streetNameStore,
	                                        streetNumberStore, storeTown,
	                                        storeRegionCode,postalCodeStore) + "</div><a href='tel:"
	                                + phoneStore + "' title='tel:" + phoneStore
	                                + "'>" + phoneStore + "</a>"); //Setting the store name

					  var storeHours = "";
					  if (data.openingHours != null) {
						  var weekdayOpeningList = data.openingHours.weekDayOpeningList;
						  if (weekdayOpeningList != null) {
							  weekdayOpeningList.push(weekdayOpeningList.shift());
							  $.each(weekdayOpeningList, function(_weekDayIndex, weekDay) {
								  if (weekDay.weekDayName != null) {
									  storeHours = storeHours + "<div class='hours-section'> <span class='week_days font14 bold'>" + ACC[weekDay.weekDayName];
									  storeHours = storeHours + "</span><span class='hours_opening font14'>";
									  if (weekDay.closed) {
										  storeHours = storeHours + ACC.storeClosed + "</span></div>";
									  } else {
										  storeHours = storeHours + weekDay.openingTime.formattedHour + " - " +
											  weekDay.closingTime.formattedHour + "</span></div>";
									  }
								  }
							  });
						  }
					  }
					  $(".store-time-found").empty().append(
						  "<div>" + "<h5>" + ACC.storeHours + "</h5>" + storeHours + "</div>");
	                $(".phone-found").empty().append(
	                        "<a class='font-blue bold' href='tel:"
	                                + phoneStore + "' title='tel:" + phoneStore
	                                + "'>" + phoneStore + "</a>");
	        		$('.find-yourStore').addClass("hidden");
	           	
	        }
	    }
      $(".store-detail-section .store-detail").html(streetNumberStore+" "+streetNameStore+"</br> "+storeTown+", "+storeRegionCode+", "+((postalCodeStore.indexOf('-')!=-1)?postalCodeStore.split('-')[0]:postalCodeStore)+"</br> "+phoneStore);
      $("#storeSelected").val(storeNum);
      $(".store-detail-section a").hide();
      $(".store-detail-section .changeLoc").show();
      $("#ContactUsStoreNumber").val(storeNum);
}

window.addEventListener("orientationchange", function () {
	$(".locationSearchCloseButton").trigger("click");
});

    $("body").on("click", "#btn-store-details-modal", function (e) {
        storeLocaterModal.modal("show");
        $(".storeContainerInputForm .selected-zip-container").removeClass("hide");
        $(".return-to-map").addClass("hide");
    });

// Store Change Warning Toggle Script
function storeChangeWarningToggler() {
    let isStoreSelected = getCookieByName("DeliveryStoreAssigned");
    if(isStoreSelected) {
        $(".storeChangeWarning").show();
    } else {
        $(".storeChangeWarning").hide();
    }
}
function findMyStoreBtn() {
	storeLocaterModal.modal("show");
    $(".store-locator .modal-body").addClass(
        "store-preview-padding"
    );
}
function callStore(phoneNumber) {
	window.location.href = 'tel://' + phoneNumber;
}